<template>
  <div class="mypage_item_history_wrap">
    <!--       아이템 관심 리스트 레이아웃-->
    <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
           style = "table-layout: auto; width: 1200px; table-layout: fixed;">
      <colgroup>
        <col width="90px" />
        <col width="135px" />
        <col width="190px" />
        <col width="130px" />
      </colgroup>
      <tr class="title_row">
        <th colspan="1">No</th>
        <th colspan="1">{{$t('image')}}</th>
        <th colspan="1">{{$t('member_name')}}</th>
        <th colspan="1">{{$t('type')}}</th>
      </tr>
      <tr v-for="(data,index) in memberData" :key="`creator${index}`"  @click="movePage(data)" style="cursor:pointer;">
        <td>{{ tableIdx(index) }}</td>  <!-- No -->
        <td><img :src="returnThumbnail(data)" width="78" height="49"/></td>
        <td class="t_subject">{{ data.mb_nick }}</td>  <!-- 회원명 -->
        <td>
          {{returnMemberType()}}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import AuthMixins from "@/mixins/AuthMixins";

  export default {
  name: "MemberListInterestCreatorLayout",
  mixins: [imageOption,AuthMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    memberData: {},
    paginationData: {default: {total: 0}},
  },
  data() {
    return {
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(data) {
      this.$router.push(`/creator/${data.mb_uuid}`)
    },
    tableIdx(index) {
      return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
    },
    returnThumbnail(data) {
      if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
        return `${data.Files.Profile[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
      }
    },
    returnMemberType(){
      let type = '';
      type = this.$t(this.getMemberType());
      if(this.getIsPremium()){
        type += ','+this.$t(this.getIsPremium());
      }
      return type;

    }

  },
  watch: {},
}
</script>

<style scoped>

</style>
